import React from 'react'
import Layout from '../layouts/Layout'
import { Helmet } from 'react-helmet'
import LondonNavbar from '../components/london/navbar'
import "../assets/stylesheets/london.scss"

const LondonTemplate = ({ children }) => {

  const title = 'Trailblazer Bootcamp London'

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
      </Helmet>

      <LondonNavbar />

      <div className="content">
        {children}
      </div>
    </Layout>
  )
}

export default LondonTemplate
