import React from 'react'

const LondonNavbar = () => {
    return (
        <>
            <div className="menu-holder wide version2">
                <div className="menu-nav">
                    <ul>
                        <li>
                            <a href="/bootcamps/london">Home</a>
                        </li>
                        <li>
                            <a href="/bootcamps/london/schedule">Schedule</a>
                        </li>
                        <li>
                            <a href="/bootcamps/london/tracks">Tracks</a>
                        </li>
                        <li>
                            <a href="/bootcamps/london/location">Location</a>
                        </li>
                        <li>
                            <a href="/bootcamps/london/faq">FAQ</a>
                        </li>
                        <li>
                            <a href="https://bootcamp.jomablue.com/reg/store/salesforce-trailblazer-bootcamp-london" target="_blank">REGISTER NOW</a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default LondonNavbar